import { render, staticRenderFns } from "./market-price-futures.vue?vue&type=template&id=6e716b31&scoped=true"
import script from "./market-price-futures.vue?vue&type=script&lang=js"
export * from "./market-price-futures.vue?vue&type=script&lang=js"
import style0 from "./market-price-futures.vue?vue&type=style&index=0&id=6e716b31&prod&lang=css"
import style1 from "./market-price-futures.vue?vue&type=style&index=1&id=6e716b31&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e716b31",
  null
  
)

export default component.exports